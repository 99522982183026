import Q from 'q';
import _ from 'underscore';

export default {
    components: {},
    data() {
        return {
            original_test_case_list: [
                //<editor-fold desc="type=001">
                {
                    to: {name: 'admin-global-query-test-page-001'},
                    type: `001`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002'},
                    type: `001`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003'},
                    type: `001`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004'},
                    type: `001`,
                },
                //</editor-fold>

                //<editor-fold desc="type=101">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {aaa: 111}},
                    type: `101`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {aaa: 111}},
                    type: `101`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {aaa: 111}},
                    type: `101`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {aaa: 111}},
                    type: `101`,
                },
                //</editor-fold>

                //<editor-fold desc="type=102">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {bbb: 222}},
                    type: `102`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {bbb: 222}},
                    type: `102`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {bbb: 222}},
                    type: `102`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {bbb: 222}},
                    type: `102`,
                },
                //</editor-fold>

                //<editor-fold desc="type=111">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {aaa: 666}},
                    type: `111`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {aaa: 666}},
                    type: `111`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {aaa: 666}},
                    type: `111`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {aaa: 666}},
                    type: `111`,
                },
                //</editor-fold>

                //<editor-fold desc="type=112">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {bbb: 777}},
                    type: `112`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {bbb: 777}},
                    type: `112`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {bbb: 777}},
                    type: `112`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {bbb: 777}},
                    type: `112`,
                },
                //</editor-fold>

                //<editor-fold desc="type=201">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {aaa: 111, bbb: 222}},
                    type: `201`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {aaa: 111, bbb: 222}},
                    type: `201`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {aaa: 111, bbb: 222}},
                    type: `201`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {aaa: 111, bbb: 222}},
                    type: `201`,
                },
                //</editor-fold>

                //<editor-fold desc="type=211">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {aaa: 666, bbb: 777}},
                    type: `211`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {aaa: 666, bbb: 777}},
                    type: `211`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {aaa: 666, bbb: 777}},
                    type: `211`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {aaa: 666, bbb: 777}},
                    type: `211`,
                },
                //</editor-fold>

                //<editor-fold desc="type=301">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {aaa: 111, bbb: 222, ccc: 333}},
                    type: `301`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {aaa: 111, bbb: 222, ccc: 333}},
                    type: `301`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {aaa: 111, bbb: 222, ccc: 333}},
                    type: `301`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {aaa: 111, bbb: 222, ccc: 333}},
                    type: `301`,
                },
                //</editor-fold>

                //<editor-fold desc="type=311">
                {
                    to: {name: 'admin-global-query-test-page-001', query: {aaa: 666, bbb: 777, ccc: 888}},
                    type: `311`,
                },
                {
                    to: {name: 'admin-global-query-test-page-002', query: {aaa: 666, bbb: 777, ccc: 888}},
                    type: `311`,
                },
                {
                    to: {name: 'admin-global-query-test-page-003', query: {aaa: 666, bbb: 777, ccc: 888}},
                    type: `311`,
                },
                {
                    to: {name: 'admin-global-query-test-page-004', query: {aaa: 666, bbb: 777, ccc: 888}},
                    type: `311`,
                },
                //</editor-fold>
            ],
        };
    },
    computed: {
        test_case_list() {
            const __this = this;
            const original_test_case_list = __this.original_test_case_list;
            const test_case_list = _.chain(original_test_case_list)
                .groupBy(function (m) {
                    return m.type;
                })
                .map(function (property_value, property_name) {
                    const type = property_name;
                    const list = property_value;
                    const o = {};
                    o.type = type;
                    o.list = list;
                    return o;
                })
                .sortBy(function (m) {
                    return m.type;
                })
                .value()
            ;
            // console.log(test_case_list);
            // debugger
            return test_case_list;
        },
    },
    methods: {},
    created() {
        // const __this = this;
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
