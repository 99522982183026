import Q from 'q';
import _ from 'underscore';
import Test from '../component/test/index.vue';

export default {
    components: {
        Test,
    },
    data() {
        return {};
    },
    methods: {},
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        //测试用例会使用到(xxx.mocha.js)
        global.$router = __this.$router;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
